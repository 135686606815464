/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CheckoutDeliveryOption {
    border-block-start: 0;
    padding: 0;
    margin-block: 0 16px;

    @include desktop {
        margin-inline: 24px 0;
        flex-basis: 100%;
    }

    &-Button {
        display: flex;
        align-items: center;
        width: 100%;
        padding-block: 24px;
        padding-inline: 20px;
        outline: 1px solid var(--stroke-color);

        span {
            font-size: 16px;
            line-height: 24px;
        }

        &_isSelected {
            outline: 2px solid var(--secondary-blue-color);
        }

        @include desktop {
            max-width: 450px;
        }
    }

    &:first-child {
        @include desktop {
            margin-inline: 0;
        }
    }

    &:last-child {
        @include desktop {
            margin-block: 0;
        }
    }
}
