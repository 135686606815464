/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --checkout-payment-select-color: var(--secondary-blue-color);
    --checkout-payment-border-color: var(--stroke-color);
    --checkout-payment-background-color: var(--background-color);
}

.CheckoutPayment {
    border: none;
    font-weight: 400;
    flex-basis: 100%;
    padding: 0;

    &-Button {
        border: 1px solid var(--checkout-payment-border-color);
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        padding-block: 24px;
        
        @include desktop {
            align-items: center;
        }

        &_isSelected {
            border: 2px solid var(--checkout-payment-select-color);
            padding-block: 23px;
            padding-inline: 19px;
        }
    }

    &:not(:first-child) {
        margin-block-start: 16px;

        @include desktop {
            margin-inline-start: 24px;
            margin-block-start: 0;
        }
    }

    .Field {
        &_type {
            &_radio {
                margin-block-start: 0;
            }
        }

        input {
            height: min-content;
        }

        &-RadioLabel {
            line-height: 22px;
            
            .input-control {
                flex-shrink: 0;
            }
        }
    }
    
    @include desktop {
        display: flex;
        flex-direction: stretch;
    }
}
